{
  "private": true,
  "type": "module",
  "version": "161.17.2",
  "scripts": {
    "build": "run-s 'build:*'",
    "build:css": "tailwindcss -m -i ./styles/app.css -o app/styles/app.css",
    "build:api": "rm -rf app/api && openapi --indent 2 --name API --input openapi.json --output app/api && prettier --write app/api",
    "build:remix": "remix build",
    "fetch:api": "dotenv -- bash -c 'curl $API_URL/api/v1/openapi.json > openapi.json' && prettier --write openapi.json",
    "dev": "dotenv run-p build:css build:api && run-p 'dev:*' | pino-pretty",
    "dev:css": "tailwindcss -w -i ./styles/app.css -o app/styles/app.css",
    "dev:api": "chokidar 'openapi.json' -c 'yarn build:api'",
    "dev:remix": "remix dev -c \"tsx ./server.ts\"",
    "test:gen": "npx playwright codegen http://localhost:3000",
    "test:pw": "DEBUG=pw:browser,pw:api,pw:webserver playwright test",
    "test:pw-local": "rm -rf ./playwright/output || true && DEBUG=pw:browser,pw:api,pw:webserver playwright test --trace on",
    "test:pw-debug": "DEBUG=pw:browser,pw:api,pw:webserver playwright test --debug",
    "test:pw-ui": "DEBUG=pw:browser,pw:api,pw:webserver playwright test --ui",
    "test:pw-report": "playwright show-report",
    "test:pw-trace": "playwright show-trace",
    "format": "prettier --write .",
    "check-format": "prettier . --check",
    "lint": "eslint --cache --cache-location ./node_modules/.cache/eslint --ext .js,.ts,.jsx,.tsx app",
    "typecheck": "tsc -b",
    "check-code": "run-p lint typecheck",
    "start": "NODE_ENV=production tsx ./server.ts",
    "start:local": "dotenv tsx ./server.ts",
    "reinstall": "run-p 'clean' && yarn",
    "clean": "run-p 'clean:*' && run-p 'del:node_modules'",
    "clean:pw": "shx rm -rf playwright/output || true",
    "clean:builds": "shx rm -rf build || true && shx rm -rf public/build || true",
    "clean:tests": "shx rm -rf playwright-report || true && shx rm -rf playwright-results || true && shx rm -rf test-results || true",
    "clean:cache": "shx rm -rf .cache || true",
    "clean:api": "shx rm -rf app/api || true",
    "clean:tsbuildInfo": "shx rm tsconfig.app.tsbuildinfo || true && shx rm playwright/tsconfig.tsbuildinfo || true",
    "del:node_modules": "shx rm -rf node_modules || true",
    "lint-staged": "lint-staged",
    "unused-exports": "yarn dlx ts-prune -p tsconfig.app.json -e"
  },
  "lint-staged": {
    "*.{ts,tsx}": [
      "pretty-quick --staged",
      "eslint --quiet --fix"
    ]
  },
  "dependencies": {
    "@codemirror/autocomplete": "^6.6.0",
    "@codemirror/lang-sql": "^6.4.1",
    "@hookform/resolvers": "^3.5.0",
    "@iconscout/react-unicons": "^2.0.2",
    "@microsoft/fetch-event-source": "^2.0.1",
    "@radix-ui/react-accordion": "^1.2.0",
    "@radix-ui/react-alert-dialog": "^1.0.3",
    "@radix-ui/react-aspect-ratio": "^1.0.2",
    "@radix-ui/react-avatar": "^1.0.2",
    "@radix-ui/react-checkbox": "^1.0.3",
    "@radix-ui/react-collapsible": "^1.1.0",
    "@radix-ui/react-context-menu": "^2.1.3",
    "@radix-ui/react-dialog": "^1.0.3",
    "@radix-ui/react-dropdown-menu": "^2.0.4",
    "@radix-ui/react-hover-card": "^1.0.5",
    "@radix-ui/react-label": "^2.0.1",
    "@radix-ui/react-menubar": "^1.0.2",
    "@radix-ui/react-navigation-menu": "^1.1.2",
    "@radix-ui/react-popover": "^1.0.5",
    "@radix-ui/react-portal": "^1.0.1",
    "@radix-ui/react-progress": "^1.0.2",
    "@radix-ui/react-radio-group": "^1.1.2",
    "@radix-ui/react-scroll-area": "^1.0.3",
    "@radix-ui/react-select": "^1.2.1",
    "@radix-ui/react-separator": "^1.0.2",
    "@radix-ui/react-slider": "^1.1.1",
    "@radix-ui/react-slot": "^1.1.0",
    "@radix-ui/react-switch": "^1.0.2",
    "@radix-ui/react-tabs": "^1.0.3",
    "@radix-ui/react-toast": "^1.1.3",
    "@radix-ui/react-toggle": "^1.0.2",
    "@radix-ui/react-tooltip": "^1.0.5",
    "@react-spring/web": "^9.7.3",
    "@remix-run/express": "^1.19.3",
    "@remix-run/node": "^1.19.3",
    "@remix-run/react": "^1.19.3",
    "@remix-run/serve": "^1.19.3",
    "@tanstack/react-query": "^5.0.5",
    "@tanstack/react-query-devtools": "^5.4.2",
    "@tanstack/react-table": "^8.17.3",
    "@tanstack/react-virtual": "^3.1.3",
    "@total-typescript/ts-reset": "^0.5.1",
    "@uiball/loaders": "^1.3.0",
    "@uiw/react-codemirror": "^4.19.4",
    "@use-gesture/react": "^10.3.0",
    "address": "^1.2.2",
    "chalk": "^5.3.0",
    "chokidar": "^3.6.0",
    "class-variance-authority": "^0.6.0",
    "close-with-grace": "^1.2.0",
    "clsx": "^1.2.1",
    "cmdk": "^0.2.0",
    "compression": "^1.7.4",
    "copy-to-clipboard": "^3.3.3",
    "dayjs": "^1.11.8",
    "dequal": "^2.0.3",
    "echarts": "^5.5.0",
    "express": "^4.20.0",
    "fuse.js": "^7.0.0",
    "get-port": "^7.0.0",
    "helmet": "^7.0.0",
    "highlight.js": "^11.10.0",
    "immer": "^10.0.3",
    "isbot": "^3.6.5",
    "lodash-es": "^4.17.21",
    "lodash.clamp": "^4.0.3",
    "lucide-react": "^0.343.0",
    "markdown-to-jsx": "^7.3.2",
    "morgan": "^1.10.0",
    "nanoid": "^4.0.1",
    "nprogress": "^0.2.0",
    "pino": "^8.8.0",
    "prism-react-renderer": "^2.0.4",
    "react": "^18.2.0",
    "react-dnd": "^16.0.1",
    "react-dnd-html5-backend": "^16.0.1",
    "react-dom": "^18.2.0",
    "react-error-boundary": "^4.0.13",
    "react-hook-form": "^7.51.5",
    "react-hotkeys-hook": "^4.4.0",
    "react-merge-refs": "^2.1.1",
    "react-resizable-panels": "^0.0.55",
    "react-use-measure": "^2.1.1",
    "recharts": "^2.12.3",
    "remix-utils": "^6",
    "rfdc": "^1.4.1",
    "slugify": "^1.6.6",
    "source-map-support": "^0.5.21",
    "tailwind-merge": "^1.14.0",
    "tailwindcss": "^3.4.1",
    "tailwindcss-animate": "^1.0.6",
    "tailwindcss-radix": "^2.8.0",
    "ts-key-enum": "^2.0.12",
    "use-deep-compare-effect": "^1.8.1",
    "use-immer": "^0.9.0",
    "use-resize-observer": "^9.1.0",
    "uuid": "^9.0.1",
    "valtio": "^1.11.3",
    "vega": "^5.30.0",
    "vega-embed": "^6.26.0",
    "vega-lite": "^5.21.0",
    "zod": "^3.22.4"
  },
  "devDependencies": {
    "@faker-js/faker": "^8.3.1",
    "@hookform/devtools": "^4.3.1",
    "@playwright/test": "^1.41.1",
    "@remix-run/dev": "^1.19.3",
    "@tailwindcss/typography": "^0.5.10",
    "@testing-library/dom": "^10.3.1",
    "@testing-library/jest-dom": "^5.16.5",
    "@testing-library/react": "^13.4.0",
    "@testing-library/user-event": "^14.5.2",
    "@types/compression": "^1.7.3",
    "@types/eslint": "^8.4.10",
    "@types/express": "^4.17.17",
    "@types/lodash-es": "^4",
    "@types/lodash.clamp": "^4",
    "@types/morgan": "^1.9.5",
    "@types/node": "^22.0.0",
    "@types/nprogress": "^0.2.0",
    "@types/react": "^18.0.26",
    "@types/react-dom": "^18.0.9",
    "@types/source-map-support": "^0.5.7",
    "@types/uuid": "^9",
    "@typescript-eslint/eslint-plugin": "^5.59.2",
    "@typescript-eslint/parser": "^7.16.1",
    "autoprefixer": "^10.4.13",
    "chokidar-cli": "^3.0.0",
    "dotenv-cli": "^7.0.0",
    "eslint": "^8.31.0",
    "eslint-config-airbnb": "^19.0.4",
    "eslint-config-airbnb-typescript": "^17.0.0",
    "eslint-config-prettier": "^8.6.0",
    "eslint-import-resolver-typescript": "^3.5.2",
    "eslint-plugin-import": "^2.26.0",
    "eslint-plugin-jsx-a11y": "^6.6.1",
    "eslint-plugin-prettier": "^4.2.1",
    "eslint-plugin-promise": "^6.1.1",
    "eslint-plugin-react": "^7.31.11",
    "eslint-plugin-react-hooks": "^4.6.0",
    "eslint-plugin-valtio": "^0.6.2",
    "lint-staged": "^15.0.0",
    "npm-run-all2": "^6.2.2",
    "openapi-typescript-codegen": "^0.25.0",
    "pino-pretty": "^9.1.1",
    "postcss": "^8.4.39",
    "prettier": "^2.8.2",
    "prettier-plugin-tailwindcss": "^0.2.1",
    "pretty-quick": "^3.1.3",
    "shx": "^0.3.4",
    "tsx": "^4.6.2",
    "typescript": "^4.9.4",
    "wait-on": "^7.2.0"
  },
  "resolutions": {
    "express": "^4.20.0",
    "yaml": "^2.2.2",
    "send": "^0.19.0",
    "@radix-ui/react-focus-scope": "1.0.4",
    "@radix-ui/react-popper@1.1.3": "patch:@radix-ui/react-popper@npm%3A1.1.3#./.yarn/patches/@radix-ui-react-popper-npm-1.1.3-d7804f4c70.patch",
    "@radix-ui/react-popper@1.1.2": "patch:@radix-ui/react-popper@npm%3A1.1.3#./.yarn/patches/@radix-ui-react-popper-npm-1.1.3-d7804f4c70.patch"
  },
  "engines": {
    "node": ">=18"
  },
  "packageManager": "yarn@3.3.1"
}
